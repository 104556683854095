.result-promo {
  display: flex;
  background-color: var(--color-bg);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 60px;
  padding-bottom: 80px;
  color: white;
}

.promo.promo--big {
  max-width: 800px;
}

.promo--big .promo-companies {
  text-align: center;
}
.promo-companies img {
  max-width: 219px;
}

.promo-companies-text {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-white);
}

.promo-title.promo-title-big {
  font-size: 2rem;
  line-height: 120%;
  text-align: center;
  margin-bottom: 20px;
}

.promo-title,
.promo-text {
  color: var(--color-white);
}

.promo-text {
  font-size: 1rem;
  line-height: 150%;
  max-width: 580px;
  margin: auto;
  margin-bottom: 30px;
}

.promo--big .promo-text {
  text-align: center;
}

.promo-ctas {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.promo--big .promo-ctas {
  justify-content: center;
}

@media screen and (min-width: 480px) {
  .promo-title.promo-title-big {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .result-promo {
    padding-top: 80px;
  }
  .promo-ctas {
    flex-direction: row;
    column-gap: 20px;
  }
}
