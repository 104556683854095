.stats-panel-score {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
}
@media screen and (min-width: 450px) {
  .stats-panel-score {
    flex: 1 0 calc(50% - 2rem);
  }
}
@media screen and (min-width: 990px) {
  .stats-panel-score {
    flex: 0 1 auto;
    width: auto;
  }
}
.stats-panel-score .stats-panel-score-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.stats-panel-score .stats-panel-score-info p {
  color: var(--color-grey-text);
}
.stats-panel-score .stats-panel-score-info .is--bad,
.stats-panel-score .stats-panel-score-info .is--avarage,
.stats-panel-score .stats-panel-score-info .is--good {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 133.333%;
}
.stats-panel-overall-all .stats-panel-overall-wrapper .is--bad {
  color: #20b5e3;
}
.stats-panel-overall-all .stats-panel-overall-wrapper .is--avarage {
  color: var(--color-primary);
}
.stats-panel-overall-all .stats-panel-overall-wrapper .is--good {
  color: var(--color-primary-focus);
}