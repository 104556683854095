.form-control {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.input-field {
  border-radius: 0.75rem;
  border: 1px solid var(--color-gray);
  background-color: #fff;
  padding: 0rem 1.25rem;
  height: 54px;
  font-size: 1rem;
  outline: none;
  transition: all 0.4s ease-in-out;
}
.input-field:focus {
  border: 1px solid var(--color-text);
}
.warning {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: var(--color-primary);
  font-size: 12px;
  line-height: 110%;
  margin-top: 0;
  animation: slideIn 0.5s ease-in-out;
}

.form-dark .input-field {
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.04);
  color: #fff;
}
.form-dark .input-field:focus {
  border: 1px solid #fff;
}

.input-field.error {
  border: 1px solid var(--color-primary);
}
