.stats-title {
  font-weight: 600;
  line-height: 133.333%;
  margin-bottom: 1.5rem;
}

.stats-panel-overall-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 2rem;
}
@media screen and (max-width: 449px) {
  .stats-panel-overall-wrapper {
    width: 100%;
  }
}
@media screen and (min-width: 450px) {
  .stats-panel-overall-wrapper {
    flex-direction: row;
  }
}
@media screen and (min-width: 990px) {
  .stats-panel-overall-wrapper {
    justify-content: center;
    gap: 2.5rem;
  }
}

.stats-panel-overall.stats-panel-overall-all {
  margin-top: 2rem;
}

.stats-panel-overall {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid var(--color-gray);
  border-radius: 1.5rem;
  margin-bottom: 1.5rem;
}

.stats-panel-overall .stats-panel-title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 125%;
}

.stats-panel-overall .stats-panel-title--small {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 125%;
}

.stats-panel-overall .stats-panel-title .is--avarage {
  font-weight: 700;
  color: #1186db;
  margin-left: 0.5rem;
}

.stats-panel-scores {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  border: 1px solid var(--color-gray);
  border-radius: 1.5rem;
  margin-bottom: 3.5rem;
}
@media screen and (min-width: 450px) {
  .stats-panel-scores {
    flex-direction: row;
  }
}
@media screen and (min-width: 990px) {
  .stats-panel-scores {
    justify-content: center;
    gap: 2.5rem;
  }
}
.is--bad {
  color: #20b5e3;
}
.is--avarage {
  color: #1186db;
}
.is--good {
  color: #1959e6;
}

.stats-panel .stats-panel-bottom {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 3rem;
}
.stats-panel .stats-panel-bottom .stats-panel-promo {
  order: 2;
}
.stats-panel .stats-panel-bottom .stats-panel-extra {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  order: 1;
}
@media screen and (min-width: 750px) {
  .stats-panel .stats-panel-bottom .stats-panel-promo {
    order: 1;
  }
  .stats-panel .stats-panel-bottom .stats-panel-extra {
    order: 2;
  }
  .stats-panel .stats-panel-bottom {
    grid-template-columns: 0.3fr 1.7fr;
  }
}
.stats-panel .stats-panel-bottom .stats-panel-promo .promo {
  position: sticky;
  top: 110px;
  background-color: var(--color-bg);
  border-radius: 1rem;
  padding: 1.5rem;
  color: #fff;
}
.stats-panel-promo .promo .promo-companies {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.stats-panel-promo .promo .promo-companies-text {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8rem;
}
.stats-panel-promo .promo .promo-title {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.stats-panel-promo .promo .promo-text {
  color: #fff;
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 1.5rem;
}

.stats-panel-extra-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  column-gap: 0;
  row-gap: 1px;
  background-color: #fff;
}
.stats-border {
  padding: 2rem;
  border: 1px solid var(--color-gray);
  border-radius: 1.5rem;
}
.stats-panel-extra .stats-panel-issues .stats-panel-issue {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: flex-start;
  background-color: #fff;
  padding: 0.75rem 0.5rem;
}
.stats-panel-extra .stats-panel-issues img {
  margin-top: 5px;
}
.stats-panel-extra .stats-panel-issues .stats-panel-issue-content {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 171.429%;
  color: var(--color-grey-text);
}

.stats-panel-extra .stats-panel-issues .stats-panel-issue-content p {
  color: var(--color-text);
  opacity: 0.6;
}
.stats-panel-extra .stats-panel-issues .stats-panel-issue-content span {
  font-size: 2rem;
  font-weight: 600;
  line-height: 125%;
}

.stats-panel-experience .stats-panel-extra-stats {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-rows: auto;
  /* column-gap: 0;
  row-gap: 1.5rem; */
}
.stats-panel-experience .stats-panel-exp-vital-title {
  grid-column: span 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 1.5rem;
}
.stats-panel-experience .stats-panel-exp-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: #fff;
}
.stats-panel-experience .stats-panel-exp {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.75rem;
  padding: 2.25rem 0.5rem;
}
.stats-panel-experience .stats-panel-exp .stats-panel-exp-icon {
  margin-top: 5px;
}
.stats-panel-experience .stats-panel-exp .stats-panel-exp-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  flex: 1 0 auto;
}
.stats-panel-experience .stats-panel-exp .stats-panel-exp-content p {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 171.429%;
  color: var(--color-text);
  opacity: 0.6;
}
