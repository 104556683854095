.input-label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.01rem;
  color: var(--color-text);
}

.form-dark .input-label {
  color: #fff;
}
