.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1.25rem 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}
@media screen and (min-width: 991px) {
  .header {
    padding: 1.25rem 32px;
  }
}
.header .logo {
  display: block;
  max-width: 150px;
}
@media screen and (min-width: 450px) {
  .header .logo {
    max-width: none;
  }
}
.header.header-white {
  justify-content: space-between;
  background-color: #fff;
}
.header .logo > div {
  display: flex;
}
