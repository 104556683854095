.page-select-bottom {
  position: absolute;
  top: calc(100% - 8px);
  left: 0;
  width: calc(100% - 2px);
  border-radius: 0 0 0.75rem 0.75rem;
  border: 1px solid var(--ccdae-7, #ccdae7);
  border-top: none;
  background-color: #fff;
  font-size: 1rem;
  z-index: 99;
}

.page-select-bottom .react-select-custom__menu {
  position: static;
  border-radius: 0;
  border: none;
  box-shadow: none;
}
.page-select-bottom .react-select-custom__control {
  border: none;
  border-radius: 0.5rem;
  background: rgba(204, 218, 231, 0.24);
  margin: 0.5rem 0.5rem 0 0.5rem;
  height: 48px;
  padding: 0 0.5rem;
}
.page-select-bottom .react-select-custom__indicators {
  order: 1;
}
.page-select-bottom .react-select-custom__value-container {
  order: 2;
}
