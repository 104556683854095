@import-normalize;

:root {
  --color-bg: #092540;
  --color-white: #fff;
  --color-text: #092540;
  --color-grey-text: #536778;
  --color-heading: #092540;
  --color-primary: #9966ff;
  --color-primary-hover: #6b47b3;
  --color-primary-focus: #5e3f9d;
  --color-turquoise: #10efe2;
  --color-gray: #e3ebf2;
  --color-grey-dark: #CCDAE7;
  --color-blue-light: #20B5E3;
  --color-blue-medium: #1186DB;
  --color-blue-dark: #1959E6;

  --font-family: "Inter", sans-serif;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  line-height: 150%;
  font-weight: 500;
}
.page {
  background-color: var(--color-bg);
  color: var(--color-white);
  padding: 0 2rem;
}
.App {
  /* overflow: hidden; */
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin: 0;
  line-height: 120%;
  color: var(--color-heading);
}
.h3 {
  font-size: 2rem;
}
p {
  margin: 0;
  color: var(--color-text);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 100%;
}

.container {
  max-width: 1128px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}

/* Form styling */
.form {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
}
.form .form-fields {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.form .form-actions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 479px) {
  .h3 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.clutch-widget {
  max-width: 200px;
}
