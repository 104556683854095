.result.container {
  max-width: 1128px;
  margin: auto;
  padding-left: 32px;
  padding-right: 32px;
}
@media screen and (max-width: 991px) {
  .result.container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.result-top {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  margin: 0 auto 0 auto;
}
.result-top p {
  opacity: 0.6;
}
.result-top .result-url-link {
  color: inherit;
  text-decoration: underline 0.1em var(--color-text);
  text-underline-offset: 0.2em;
  transition: text-decoration-color 300ms;
}
.result-top .result-url-link:hover {
  text-decoration: underline 0.1em transparent;
}
.result-dropdown {
  margin-bottom: 2.5rem;
}
.result-dropdown .page-select {
  position: relative;
  max-width: 440px;
  margin: 0 auto;
}
.result-dropdown .page-select .page-select-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  padding: 0rem 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid var(--ccdae-7, #ccdae7);
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
}
.result-dropdown .page-select .page-select-top svg {
  transform: rotate(-180deg);
  transition: transform 0.2s ease-in-out;
}
.result-dropdown .page-select .is-open svg {
  transform: rotate(0);
  transition: transform 0.2s ease-in-out;
}

.result-results {
  margin-bottom: 96px;
}

.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1128px;
    margin: auto;
    padding: 32px;
}

.error-text {
  color: var(--color-primary);
}

.download-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1128px;
  margin: auto;
  padding: 32px;
}
