.btn {
  font-family: var(--font-family);
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 133.333%;
  letter-spacing: -0.0225rem;
  display: flex;
  padding: 0.5rem 1.1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 62.4375rem;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  transition: all 0.4s ease-in-out;
}
.btn:hover {
  cursor: pointer;
  background-color: var(--color-primary-hover);
  border: 1px solid var(--color-primary-hover);
}
.btn:active {
  background-color: var(--color-primary-focus);
}
.btn-secondary {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
@media screen and (min-width: 450px) {
  .btn {
    font-size: 1.125rem;
    padding: 0.6875rem 1.25rem 0.8125rem 1.5rem;
  }
}
