.footer {
  padding-top: 132px;
}
.footer-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  row-gap: 48px;
}
.footer-top-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
}

.footer-proof {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}

.footer-logo {
  height: 48px;
}

.footer-logo a {
  display: block;
  height: 100%;
}

.footer-logo img.logo {
  height: 100%;
}
.footer-socials ul {
  list-style: none;
  padding: 0;
  display: flex;
  column-gap: 20px;
  justify-content: center;
}
.social-link {
  border: 1px solid var(--color-gray);
  border-radius: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-link:hover {
  background: var(--color-gray);
}
.footer-bottom {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
  padding: 24px 0;
  font-size: 1.125rem;
}
.footer-bottom p {
  color: rgba(30, 40, 51, 0.5);
}

.footer-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  column-gap: 20px;
}
.footer-bottom ul li a {
  color: var(--color-text);
  padding: 13px 0;
  transition: color 0.2s ease-out;
}
.footer-bottom ul li a:hover {
  color: var(--color-primary);
}

@media screen and (min-width: 992px) {
  .footer {
    padding-top: 192px;
  }
  .footer-top {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 64px;
  }
  .footer-top-left {
    flex-direction: row;
    column-gap: 48px;
  }
  .footer-logo {
    height: 48px;
    padding-right: 48px;
    border-right: 1px solid #e3ebf2;
  }
  .footer-proof {
    height: 71px;
    flex-direction: row;
    column-gap: 32px;
  }
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
    padding: 26px 0;
    font-size: 1rem;
  }
}
