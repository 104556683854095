.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stats-tabs {
  display: flex;
  margin: 0 auto 3rem auto;
  padding: 0;
  border-bottom: 1px solid #ccdae7;
}
.stats-tabs li.react-tabs__tab {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  padding: 1rem 1.5rem;
  color: var(--color-grey-text);
  font-size: 1rem;
  line-height: 150%;
  font-weight: 600;
  border-radius: 0;
}
.stats-tabs li.react-tabs__tab.react-tabs__tab--selected {
  color: var(--color-primary);
  border: none;
  border-radius: 0;
  border-bottom: 2px solid var(--color-primary);
}
.stats-tabs li.react-tabs__tab.react-tabs__tab--selected:focus:after {
  display: none;
}
