.stack-bar {
  position: relative;
  margin-top: 50px;
  margin-bottom: 4px;
}

.stack-bar-bar {
  display: flex;
  height: 8px;
  gap: 4px;
}

.stack-bar-part {
  border-radius: 999px;
}

.stack-bar-part--bad {
  background-color: var(--color-blue-dark);
}

.stack-bar-part--avarage {
  background-color: var(--color-blue-medium);
}

.stack-bar-part--good {
  background-color: var(--color-blue-light);
}

.stack-bar-part--grey {
  background-color: var(--color-grey-dark);
}

.stack-bar-text--bad {
  color: var(--color-blue-light);
}

.stack-bar-text--good {
  color: var(--color-blue-dark);
}

.stack-bar-text--avarage {
  color: var(--color-blue-medium);
}

.stack-bar-text--na {
  color: var(--color-primary);
}

.stack-bar-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.stack-bar-indicator img {
  min-height: 26px;
}

.stack-bar-indicator span {
  position: absolute;
  left: 50%;
  top: -35px;
  transform: translateX(-50%);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-wrap: nowrap;
}