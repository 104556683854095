.animation-wrapper {
    display: flex;
    justify-content: center;
    padding: 30px 0;
}
  
.loader {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
}
  
.loader::before{
    content: '';
    display: block;
    /* padding-top: 100%; */
}

.circular {
    animation: rotate 1s linear infinite;
}

.path {
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: var(--color-turquoise);
    }
    40% {
        stroke: var(--color-blue-light);
    }
    66% {
        stroke: var(--color-primary);
    }
    80%,
    90% {
        stroke: var(--color-bg);
    }
}
  