@keyframes circle {
  to {
    stroke-dashoffset: var(--strokeVal);
  }
}
.animate-circle {
  stroke-dashoffset: 210.48670779051614;
}
.pie {
  display: inline-block;
  transform: rotate(-90deg);
}
