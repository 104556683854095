.home-full-height {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100% - 72px);
  min-height: calc(100dvh - 72px);
}
.home-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  z-index: 5;
  background-color: var(--color-bg);
}
.max-width-small {
  max-width: 600px;
  padding: 4rem 0;
}
.home-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}
.home-header h1 {
  color: #fff;
}
.home-header .home-header-sub {
  color: #fff;
  opacity: 0.6;
}
.home-container .home-form {
  padding: 0 0 2rem;
}
@media screen and (min-width: 750px) {
  .home-container .home-form {
    padding: 0 4.75rem 2.5rem;
  }
}

.home-full-height .bg-img-left,
.home-full-height .bg-img-right {
  display: none;
}
@media screen and (min-width: 750px) {
  .home-full-height::before {
    position: absolute;
    top: -30%;
    left: -300px;
    content: "";
    width: 432px;
    height: 607px;
    border-radius: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.16);
    z-index: 1;
  }
  .home-full-height::after {
    position: absolute;
    top: -40%;
    right: -300px;
    content: "";
    width: 432px;
    height: 607px;
    border-radius: 1.5rem;
    border: 1px solid rgba(255, 255, 255, 0.16);
    z-index: 1;
  }
  .home-full-height .bg-img-left {
    display: block;
    position: absolute;
    bottom: 0;
    left: -2rem;
    z-index: 1;
  }
  .home-full-height .bg-img-right {
    display: block;
    position: absolute;
    bottom: 10%;
    right: -2rem;
    z-index: 1;
  }
}

/* Progress pending Page */
.home-progress-pending {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}
